<template>
  <div>
    <span id="loading" v-if="this.loading == true">
      <Loading />
    </span>
    <div class="buttons">
      <button class="btn btn-primary" @click="filterOrder('processing')">
        Processing
      </button>
      <button class="btn btn-info" @click="changeSearch()">Search</button>
    </div>
    <div class="row search" v-if="search == true">
      <div class="col-sm-3">
        <div class="input-group input-group mb-3">
          <div class="input-group-prepend">
            <span class="input-group-text" id="inputGroup-sizing"
              >ID Order:</span
            >
          </div>
          <input class="form-control" type="text" v-model="searchId" />
        </div>
      </div>
      <div class="col-sm-3">
        <div class="input-group input-group mb-3">
          <div class="input-group-prepend">
            <span class="input-group-text" id="inputGroup-sizing">Status:</span>
          </div>
          <select class="form-control" v-model="searchStatus">
            <option value=""></option>
            <option value="processing">Processing</option>
            <option value="completed">Completed</option>
            <option value="cancelled">Cancelled</option>
          </select>
        </div>
      </div>
      <button @click="searchOrder()" id="btnSearch" class="btn btn-primary col-sm-1">Search</button>
    </div>
    <div v-else>
      <h5>Processing Orders</h5>
    </div>
    <b-table
      ref="picking"
      striped
      hover
      :items="orders"
      :fields="fields"
      class="table"
      small
      select-mode="multi"
      thead-class="text-white"
    >
    <template #cell(id)="data">
      <b-link @click="routeToOrder(data.item.id)">{{ data.item.id }}</b-link>
    </template>
      <template #cell(status)="data">
        <span v-if="data.item.status == 'processing'" class="processing">
          Processing
        </span>
        <span v-else-if="data.item.status == 'completed'" class="completed">
          Completed
        </span>
        <span v-else-if="data.item.status == 'cancelled'" class="cancelled">
          Cancelled
        </span>
        <span v-else class="otherStatus">
          {{ data.item.status }}
        </span>
      </template>
      <template #cell(date)="data">
        {{ splitDate(data.item.date) }}
      </template>
      <template #cell(articles)="data">
        <span
          v-for="article in JSON.parse(data.item.articles)"
          :key="article.id"
        >
          <span v-if="article.sku_client == undefined"
            >{{ article.sku }} - {{ article.name }} - {{ article.quantity }} uni
            <br
          /></span>
          <span v-else>
            {{ article.sku_client }} -
            <b-link @click="routeToProduct(article.sku_client)">
              {{ article.name }}
            </b-link>
            - {{ article.quantity }} uni<br />
          </span>
        </span>
      </template>
    </b-table>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import Loading from "@/components/Loading.vue";
export default {
  components: {
    Loading,
  },
  data: function () {
    return {
      searchStatus: "",
      searchId: "",
      search: false,
      orders: [],
      loading: false,
      fields: [
        {
          key: "id",
          label: "Nº Encmd.",
          tdClass: "align-middle",
          thStyle:
            "font-size: 18px ; background-color:rgb(255, 147, 147);vertical-align: middle",
        },
        {
          key: "date",
          label: "Data",
          tdClass: "align-middle",
          thStyle:
            "font-size: 18px; background-color:rgb(255, 147, 147);vertical-align: middle; width:10% ",
        },
        {
          key: "status",
          label: "Status",
          tdClass: "align-middle",
          thStyle:
            "font-size: 18px; background-color:rgb(255, 147, 147);vertical-align: middle",
        },
        {
          key: "articles",
          label: "Artigos",
          tdClass: "align-middle",
          thStyle:
            "font-size: 18px; background-color:rgb(255, 147, 147);vertical-align: middle",
        },
      ],
    };
  },
  methods: {
    routeToOrder(orderId) {
      window.open(
        `https://${window.location.host}/specialRequests/orders/${orderId}`,
        "_blank"
      );
    },
    async allOrders() {
      try {
        await this.$store.dispatch("allOrdersClient");
        this.orders = this.getOrders.data;
        console.log(this.orders);
      } catch (err) {
        alert(err);
      }
    },
    splitDate(date) {
      let split = date.split("T");
      return split[0];
    },
    async routeToProduct(productId) {
      window.open(
        `https://${window.location.host}/specialRequests/products/${productId}`,
        "_blank"
      );
    },
    async filterOrder(status) {
      this.search = false;
      try {
        await this.$store.dispatch("filterClientOrders", {
          status: status,
        });
        this.orders = this.getFilterOrders;
        console.log(this.orders);
      } catch (err) {
        alert(err);
      }
    },
    async searchOrder() {
      try {
        this.loading = true;
        await this.$store.dispatch("searchClientOrders", {
          status: this.searchStatus,
          id: this.searchId,
        });
        this.orders = this.getFilterOrders;
        console.log(this.orders);
      } catch (err) {
        alert(err);
      }finally{
        this.loading = false;
      }
    },
    async changeSearch() {
      this.searchId = "";
      this.searchStatus = "";
      this.search = true;
      this.orders = [];
    },
  },
  created() {
    this.filterOrder('processing');
  },
  computed: {
    ...mapGetters(["getOrders"]),
    ...mapGetters(["getFilterOrders"]),
  },
};
</script>
<style scoped>
.table {
  font-size: 14px;
  text-align: center;
}
.processing {
  background-color: #4ea4f0;
  color: white;
  padding: 5px;
  border-radius: 10px;
}
.completed {
  background-color: #28a541;
  color: white;
  padding: 5px;
  border-radius: 10px;
}
.cancelled {
  background-color: #d33;
  color: white;
  padding: 5px;
  border-radius: 10px;
}
.otherStatus {
  background-color: #2c9f8e;
  color: white;
  padding: 5px;
  border-radius: 10px;
}
.buttons {
  text-align: center;
  margin: 20px;
}
.search {
  text-align: center;
  margin: 10px;
}
.buttons button {
  margin: 0px 10px;
}
#btnSearch{
  height: 50%;
  width: 60%;
}
h5{
  text-align: center;
}
</style>
